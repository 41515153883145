<script>

</script>
<style>
    .send_button {
        /**/
        width: 61px;
        height: 61px;
        border-radius: 100%;
        outline: none;
        border: 0;
        background-color: #213565;
        position: absolute;
        top: -26px;
    }
    .send {

        width: 21px;
        height: 20px;

    }

</style>
<button class="send_button send_message">
    <img src="imgs/arrows_white.svg" alt="" class="send">
</button>