<script>
    import {fade} from 'svelte/transition';
    import {createEventDispatcher} from 'svelte';
    import {onMount} from 'svelte';
    import {DrawLines} from "../Helpers/DrawLine"
    import {DrawZipLine} from "../Helpers/DrowZipLine"


    const dispatch = createEventDispatcher();
    export let first_bird_active;
    export let second_bird_active;
    export let third_bird_active;
    export let fourth_bird_active;
    export let fifth_bird_active;
    export let first_customer_section;
    export let second_customer_section;
    export let third_customer_section;
    export let fourth_customer_section;
    export let is_phone;
    export let night_theme;

</script>
<style>
    .customer_message {
        font-style: normal;
        font-weight: bold;
        font-size: 44px;
        line-height: 57px;
        font-feature-settings: 'liga' off;
        color: #111A38;
        margin-bottom: 110px;
    }

    .title {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 26px;
        text-transform: uppercase;
        color: #111A38;
    }

    .text {
        width: 708px;
        height: 171px;
        max-width: 708px;
        max-height: 171px;
    }

    .first_bird {
        padding-top: 30px;
        padding-left: 7%;
    }

    .second_bird {
        padding-top: 60px;
        padding-left: 7%;
    }

    .third_bird {
        padding-top: 70px;
        padding-left: 7%;
    }

    .fourth_bird {
        padding-top: 30px;
        padding-left: 7%;
    }

    .fifth_bird {
        padding-top: 30px;
        padding-left: 7%;
    }

    .six_bird {
        /*padding-left: 20%;*/
        /*margin-top: 2%;*/
    }

    .seven_bird {
        margin-left: 20%;
    }

    .eight_bird {
        margin-left: 20%;
    }

    .nine_bird {
        margin-left: 20%;
    }

    .bird_menu {
        position: absolute;
        left: 90px;
        top: 75%;
    }

    .bird_menu_mobile {
        display: flex;
        flex-direction: column;
        height: 40%;
        align-items: center;
        justify-content: space-around;
        /*padding-bottom: 190%;*/
    }

    .view_all_button {
        position: absolute;
        top: 85%;
        left: 85%;
        color: #39A0C8;
        outline: none; /* Для синий ободки */
        border: 0;
        background: transparent;
    }

    /*.fix_width {
        width: 180px;
        color: #101b38;

    }*/

    .bold {
        font-weight: bold;
        line-height: 24px;
        color: #213565;
    }


    /*@media (max-width: 1200px) {
        .bold {
            line-height: 24px;
        }
    }

    @media (min-width: 1300px) {
        .fix_width {
            width: 220px;
        }

        .bold {
            line-height: 34px;
        }
    }

    /*@media (max-width: 1280px) {
        .font_22_12 {
            font-size: 21px;
            line-height: 33px;
        }

        @media (device-height: 1024px) {
            .fix_width {
                width: 200px;
            }
        }*/

    .col-1, .col-2 {
        padding-left: 10px;
        padding-right: 10px;
    }

    .font_22_12 {
        font-size: 23px;
        line-height: 29px;
        font-weight: 400;
        color: #111A38;
    }

    .bold {
        font-weight: bold;
        color: #213565;
    }

    /*.bird_55 {
        height: 55px;
    }*/
    .first_text {
        margin-top: 69%;
    }

    .second_text {
        margin-top: 10%;
    }

    .third_text {
        margin-top: 20%;
    }

    .fourth_text {
        padding-top: 0;
    }

    .bold {
        margin-bottom: 5px;
    }

    .bird_55 {
        margin-left: 40%;
    }

    .content_mobile {
        height: 110vh;
        flex-direction: column;
        align-items: center;
        vertical-align: center;
    }

    @media (max-width: 1024px) {
        .first_text {
            margin-top: 0%;
        }

        .second_text {
            margin-top: 15%;
        }

        .third_text {
            margin-top: 15%;
        }

        .fourth_text {
            margin-top: 15%;
        }

        .bird_55 {
            margin-left: 5%
        }

        .title {
            font-weight: bold;
            font-size: 23px;
            line-height: 35px;
            margin-bottom: 20px;
        }
    }

    @media (min-width: 768px) {
        @media (max-height: 1024px) {

            .font_22_12 {
                font-size: 23px !important;
                line-height: 34px !important;
            }

            .bold {
                font-size: 26px;
            }
        }
    }
    @media (max-width: 1024px) {
        .font_22_12 {
            font-size: 16px !important;
            line-height: 24px !important;
        }
    }
    @media (max-width: 1280px) {
        @media (max-height: 700px) {
            .font_22_12 {
                font-size: 16px !important;
                line-height: 24px !important;
            }
        }
    }
    .title_bird {
      display: flex;
        flex-direction: row;

    }
    .mobile_bird {
        position: absolute;
        right: -20%;
    }

    @media only screen and (min-width: 1024px) and (max-height: 1366px)
    and (-webkit-min-device-pixel-ratio: 1.5) and (hover: none) {
        /* ... */
       .font_22_12 {
            font-size: 16px !important;
            line-height: 24px !important;
        }
        #block_4 {
            padding-right: 4%;
        }
    }

</style>
{#if !is_phone}

{:else}
<div class="row">
    <div class="col-1"></div>
    <div class="col-8">
        <div class="content_mobile">
            <div class="first_text" id="block_1">
                {#if first_customer_section}
                <div class="title_bird">
                    <p class="title fix_width bold" transition:fade style="font-weight: bold;">PENSION FUNDS</p>
                    <img src={first_customer_section ? 'imgs/bird_set_2/bird_1_blue.svg' : 'imgs/bird_set_2/bird_1_gray.svg'}
                    alt=""
                    class="six_bird bird_55 mobile_bird" id="bird_1">
                </div>

                <p class="font_22_12 fix_width" transition:fade>Facilitation and automation of the workload of
                    pension
                    funds</p>
                {/if}

            </div>
            <div class="second_text " id="block_2">
                {#if second_customer_section}
                <div class="title_bird">
                    <p class="title fix_width bold" transition:fade>BENEFICIARIES & EMPLOYEES</p>
                    <img src={second_customer_section ? 'imgs/bird_set_2/bird_2_blue.svg' : 'imgs/bird_set_2/bird_2_gray.svg'}
                    alt=""
                    class="seven_bird bird_55 mobile_bird" id="bird_2">
                </div>
                <p class="font_22_12 fix_width" transition:fade>Help for employees to track, manage and improve
                    their
                    personal retirement savings</p>
                {/if}
            </div>
            <div class="third_text " id="block_3">
                {#if third_customer_section}
                <div class="title_bird">
                    <p class="title fix_width bold" transition:fade>ASSET MANAGER & ADVISORY SERVICES</p>
                    <img src={third_customer_section ? 'imgs/bird_set_2/bird_3_blue.svg' : 'imgs/bird_set_2/bird_3_gray.svg'}
                    alt=""
                    class="eight_bird bird_55 mobile_bird" id="bird_3">
                </div>
                <p class="font_22_12 fix_width" transition:fade>Insight and transparency in the asset
                    allocation and
                    selection of investments</p>
                {/if}
            </div>
            <div class="fourth_text" id="block_4">
                {#if fourth_customer_section}
                <div class="title_bird">
                    <p class="title fix_width bold" transition:fade>EMPLOYERS</p>
                    <img src={fourth_customer_section ? 'imgs/bird_set_2/bird_4_blue.svg' : 'imgs/bird_set_2/bird_4_gray.svg'}
                    alt=""
                    class="nine_bird bird_55 mobile_bird" id="bird_4">
                </div>
                <p class="font_22_12 fix_width" transition:fade>Reduction of overhead costs within pension fund
                    system
                    through smart digitization
                </p>
                {/if}
            </div>
        </div>
    </div>
    <div class="col-3">
        <!-- <div class="bird_menu_mobile">
         </div>-->
    </div>
</div>
{/if}

<!--CUSTOMERS-->
