<script>
    import SendMessage from "../Buttons/SendMessage.svelte"
    import Input from "../Imputs/imput.svelte"

</script>
<style>
    .customer_header {
        background: #213565;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .site {

    }

    .modal_customer {
        background: white;
        padding-top: 40px;
        padding-bottom: 30%;
        padding-left: 15px;
        padding-right: 15px;
    }

    .message_form {
        display: flex;
        flex-direction: row;
        align-items: inherit;
    }
    .send_button {
        width: 61px;
        height: 61px;
        border-radius: 100%;
        outline: none;
        border: 0;
        background-color: #213565;
        top: -26px;
    }
    .send {

        width: 21px;
        height: 20px;

    }
</style>
<div class="modal_customer" role="dialog" aria-modal="true">
    <div class="content_sec">
        <p class="font_bold_36_26">Get in touch with us. We’re looking forward.</p>
        <div style="padding-top: 8%">
            <Input placeholder="Name" width="100%"/>
        </div>
        <div style="padding-top: 8%">
            <Input placeholder="E-mail" width="100%"/>
        </div>
        <div style="padding-top: 8%" class="message_form">
            <div class="block" style="width: 100%"><Input placeholder="Message" width="100%"/></div>
            <div class="block" style="padding-top: 17px;">
                <button class="send_button">
                    <img src="imgs/arrows_white.svg" alt="" class="send">
                </button>
            </div>
        </div>
    </div>
</div>